<template>
  <div class="glove-section" v-if="glove.acf">
    <section id="product" class="s-product">
      <header class="product-header">
        <div class="container">
          <div class="row">
            <div class="col-12 col-sm-6">
              <div class="punchout text-right">
                <h2>{{ glove.acf.sku }}</h2>
              </div>
            </div>
            <!--end col-->
          </div>
          <!--end row-->
        </div>
        <!--end container-->
      </header>
      <div class="s-product-box">
        <div class="container">
          <div class="row">
            <div class="col-12 col-sm-4 col-md-4">
              <div class="s-product-img">
                <div
                  class="tab-pane fade show active"
                  id="product-2"
                  role="tabpanel"
                  aria-labelledby="product-2-tab"
                >
                  <img
                    :src="glove.acf.featured_images[activeImg].url"
                    alt="7168W_b_R_rgb"
                    class="img-fluid"
                  />
                </div>
              </div>
              <!--end s-product-img-->
              <div class="s-product-nav">
                <ul class="nav nav-tabs" id="s-product-pics-nav" role="tablist">
                  <li
                    v-for="(img, index) in glove.acf.featured_images"
                    :key="index"
                    :id="'product' + index + 'tab'"
                    class="nav-item"
                  >
                    <img
                      :src="img.url"
                      :alt="img.title"
                      class="img-fluid"
                      v-on:click="updateActiveImage(index)"
                      data-no-retina
                    />
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-12 col-sm-8 col-md-4">
              <div class="s-product-info">
                <h1 class="display-3 h3">{{ glove.acf.name }}</h1>
                <p class="h4 display-3 s-price" v-if="glove.acf.price != 0">
                  <span class="price">${{ glove.acf.price }}</span>
                </p>
                <p class="h6 display-3 s-sizing">
                  <span class="sizing">Sizes Available</span>
                </p>
                <span
                  v-for="(size, index) in glove.acf.size"
                  :key="index"
                  class="size-box"
                >{{ size }}</span>
                <a
                  :href="glove.acf.outbound_link_for_purchase_or_more"
                  target="_blank"
                  title="Buy now at Level1"
                  class="btn-buy"
                   v-if="glove.acf.buy_button_options == 'buy_level_one'"
                >
                  <span class="buy">Buy Now At</span>
                </a>
                <a
                  :href="glove.acf.outbound_link_for_purchase_or_more"
                  target="_blank"
                  title="Buy Now"
                  class="btn-buy-now"
                   v-if="glove.acf.buy_button_options == 'buy_now'"
                >
                  <span class="buy">Buy Now</span>
                </a>                
                <h3 class="display-3 h3 mt-5" v-if="glove.acf.buy_button_options == 'in_store_only'">In-Store Only</h3>
                <h3 class="display-3 h3 mt-5" v-if="glove.acf.buy_button_options == 'coming_soon'">Coming Soon</h3>
                <h3 class="display-3 h3 mt-5" v-else-if="glove.acf.buy_button_options == ''">Coming 2020/2021 Season</h3>
                <div class="i-close">
                  <a @click="$router.go(-1)" class="close-product">
                    <font-awesome-icon :icon="['fas', 'times-circle']"></font-awesome-icon>
                  </a>
                </div>
              </div>
              <!--end s-smember-info-->
            </div>
            <!--end col-->
          </div>
          <!--end row-->
        </div>
        <!--end container-->
      </div>
      <!--end member box-->
    </section>
    <section id="product-footer" class="s-product-footer" v-if="glove.acf.tabs">
      <header class="s-product-footer-nav">
        <div class="container">
          <div class="row">
            <div class="col-12 offset-0 col-xs-7 offset-xs-5">
              <ul class="nav nav-tabs" id="s-product-details-nav">
                <li
                  :class="activeTab === index ? 'nav-item active' : 'nav-item'"
                  v-for="(tab, index) in glove.acf.tabs"
                  :key="index"
                >
                  <a
                    role="tab"
                    :aria-controls="tab.tab_name"
                    aria-selected="true"
                    @click="updateActiveTab(index)"
                  >{{ tab.tab_name }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
      <div class="s-product-footer-content" id="s-product-footer-content-container">
        <div id="s-product-details" class="details-tab-container">
          <div
            class="tab-pane fade show active"
            :style="style"
            role="tabpanel"
            aria-labelledby="details-tab"
            v-for="(tab, index) in glove.acf.tabs"
            :key="index"
            v-if="activeTab == index"
            :ref="'tabContent' + index"
          >
            <div class="container">
              <div class="row" v-html="tab.content">
                <!--end col-->
              </div>
              <!--end row-->
            </div>
            <!--end container-->
          </div>
          <!--end-tab-->
        </div>
        <!--end s-product-details-->
      </div>
      <!--end s-product-footer-nav-content-->
    </section>
    <FooterMain></FooterMain>
  </div>
</template>
<script>
import { ProductService } from "@/services/WpApi";
import FooterMain from "@/components/layout/FooterMain.vue";

export default {
  name: "SingleGlove",
  components: {
    FooterMain
  },
  props: {
    slug: {
      type: String
    },
    gloveData: {
      type: Object
    }
  },
  data() {
    return {
      glove: {},
      activeImg: 0,
      activeTab: 0,
      minHeight: "400px",
      padding: "30px"
    };
  },
  computed: {
    style() {
      const style = { minHeight: this.minHeight };
      style.padding = this.padding;
      return style;
    },
    pageTitle() {
      // `this` points to the vm instance
      return this.$route.params.slug
        .replace(/-/g, " ")
        .replace(/\b\w/g, l => l.toUpperCase());
    }
  },
  metaInfo() {
    return {
      title: this.pageTitle
    };
  },
  mounted() {
    if (this.gloveData) {
      this.glove = this.gloveData;
    } else {
      // should refactor to work entirely with async/await instead of going into callback hell
      this.getGlove(this.$route.params.slug);
    }
    // this.matchHeight();
  },
  watch: {
    $route(to, from) {
      const response = this.getGlove(this.$route.params.slug);
      this.activeImg = 0;
    }
  },
  methods: {
    // matchHeight() {
    //   this.minHeight =
    // },
    async getGlove(slug) {
      const response = await ProductService.fetchProduct(slug);
      this.glove = response.data[0];
      // this.matchHeight(response.data[0]);
    },
    updateActiveImage: function(index) {
      this.activeImg = index;
    },
    updateActiveTab: function(tab) {
      this.activeTab = tab;
    }
  }
};
</script>
